<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center" style="min-height: 100vh">
    <div class="bg-img" >
      <div class="text-center text-color">
        <h3><b>This page</b> is <b>under development</b></h3>
      </div>
      <img src="../../assets/img/development.jpg" alt="development-img" />
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "emptypage",
};
</script>

<style lang="scss" scoped>
.bg-img {
  position: relative;
  display: block;
  height: 500px;
  width: 500px;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
